<template>
    <div class="app-container">
        <navBar :title="'关于壹米咻咻'" @handleGoBack="handleGoBack"></navBar>
        <div class="content">
            <img src="../../../assets/images/company_icon_img.png" alt=""/>
            <div class="text">版本号 v {{ myVersion }}</div>
        </div>
        <van-cell-group>
            <!--<van-cell is-link @click="handleUpdate" v-if="versionCode != versionObj.versionCode">
                <template #title>
                    <van-badge dot>
                        <div class="child">{{ versionCode }}检测更新{{ versionObj.versionCode }}</div>
                    </van-badge>
                </template>
            </van-cell>

            <van-cell title="检测更新(当前已是最新版本)" v-else></van-cell>-->
            <van-cell title="检测更新(当前已是最新版本)"></van-cell>

            <van-cell title="公司介绍" is-link @click="handleGo('/staticSystem/aboutCompany')"/>
            <van-cell title="服务协议" is-link @click="handleGo('/staticSystem/serviceHttps')"/>
            <van-cell title="隐私政策" is-link @click="handleGo('/staticSystem/private')"/>
        </van-cell-group>

        <van-popup v-model="showNewVersion">
            <div class="pop-content">
                <div class="new-version-con">
                    <div class="label">发现新版本</div>
                    <div class="value">V {{ versionObj.versionName }}</div>
                </div>
                <div class="title">更新内容</div>
                <div class="con">
                    <div class="item-con">1.界面全新改版，一目了然</div>
                    <div class="item-con">2.修复一些bug，提升系统安全性能</div>
                    <div class="item-con">3.消息中心改版，信息查询更清晰</div>
                    <div class="item-con">4.修复一些bug，提升系统安全性能</div>
                    <div class="item-con">5.界面全新改版，功能一目了然</div>
                </div>
                <a href="#" class="btn" @click.prevent="downApp()">立即更新</a>
                <div class="tips">建议在WIFI环境下载</div>
            </div>
        </van-popup>
    </div>
</template>
<script>
    import {fetchVersion} from '@/api/system/config';

    export default {
        data() {
            return {
                loading: false,
                finished: false,
                showNewVersion: false,
                versionObj: {},
                myVersion: localStorage.getItem('localver'),
                versionCode: 1
            };
        },

        mounted() {
            let that = this
            this.getVersion();
            mui.getJSON('./manifest.json', null, function (data) {
                var myver = JSON.stringify(data.version.name); //之前不知道，原来要JSON.stringify()

                window.localStorage.localver = myver; //将解析到的版本号进行存储
                that.myVersion = myver;
                that.versionCode = JSON.stringify(data.version.code);

            });
        },

        methods: {
            handleGoBack() {
                this.$router.replace(localStorage.getItem('indexPath'));
                this.$router.go(-1)
            },

            //下载app
            downApp(){
                plus.runtime.openURL('https://downapp.yimixiuxiu.com');
            },

            handleUpdate() {
                if (Number(this.versionObj.versionCode) > Number(this.versionCode)) {
                    this.showNewVersion = true;
                }
            },

            handleGo(path) {
                if(path == '/staticSystem/aboutCompany'){
                    this.$router.push(path);
                }else{
                    try {
                        if(plus){
                            if(path == '/staticSystem/serviceHttps'){
                                plus.runtime.openURL('https://www.yimixiuxiu.com/serviceAgree.html')
                            }else{
                                plus.runtime.openURL('https://www.yimixiuxiu.com/conceal.html')
                            }
                        }
                    }catch (e){
                        if(path == '/staticSystem/serviceHttps'){
                            document.location.href = 'https://www.yimixiuxiu.com/serviceAgree.html'
                        }else{
                            document.location.href = 'https://www.yimixiuxiu.com/conceal.html'
                        }
                    }
                }
            },

            async getVersion() {
                const result = await fetchVersion();
                this.versionObj = result.data;
            },

            handleUpdateVersion() {
            },
        },
    };
</script>
<style lang="scss" scoped>
  ::v-deep .van-cell {
    font-size: 28px;
    color: #333333;
    padding: 35px;
  }

  ::v-deep .van-badge--dot {
    width: 13px;
    height: 13px;
    background: #e64040;
  }

  ::v-deep .van-badge--fixed {
    right: -10px;
  }

  .content {
    img {
      width: 100px;
      height: 100px;
      border-radius: 100%;
      margin-bottom: 30px;
    }

    text-align: center;
    font-size: 28px;
    color: #666;
    padding: 50px 0;
  }

  // 弹窗
  ::v-deep .van-popup--center {
    border-radius: 40px;
    background: url('../../../assets/images/version_bg.png') no-repeat center;
    background-size: 100% 100%;
  }

  .pop-content {
    width: 520px;
    height: 706px;
    padding: 260px 42px 20px 42px;
    position: relative;

    .new-version-con {
      position: absolute;
      top: 60px;
      right: 60px;
      color: #fff;

      .label {
        font-size: 32px;
      }

      .value {
        font-size: 30px;
        margin-top: 20px;
      }
    }

    .title {
      font-size: 28px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 30px;
    }

    .con {
      .item-con {
        font-size: 24px;
        color: #999999;
        line-height: 40px;
      }
    }

    .btn {
      display: block;
      width: 370px;
      height: 70px;
      background: linear-gradient(177deg, #8596ff 0%, #58b7ff 100%);
      border-radius: 35px 35px 35px 35px;
      text-align: center;
      line-height: 70px;
      font-size: 30px;
      color: #fff;
      margin: 30px auto;
    }

    .tips {
      font-size: 20px;
      color: #bbbbbb;
      text-align: center;
    }
  }
</style>
